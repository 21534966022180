import { createSlice } from '@reduxjs/toolkit';
import {
    getPost, getPostAttachments, getPostAuthor, getPostHistory, getPostComments, addPostComment, deletePostComment, movePost, myPostRights, editPost, addAttachment, removeAttachment, editPostComment, deletePost, deletePosts, editPosts, 
} from '../actions/posts';
import { DEFAULT_LIMIT } from '../../utils/costants';

let DEFAULT_STATE = {
    loading:false,
    loadingComments: false,
    current: {
        attachments: [],
        history: [],
        author: null,
        comments: [],
        myRights: []
    }
}

export const postsSlice = createSlice({
    name:'posts',
    initialState: DEFAULT_STATE,
    reducers:{
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getPost.pending,(state)=>{ state.loading = true })
        .addCase(getPost.fulfilled,(state,action)=>{ 
            state.loading = false;
            state.current = {...state.current,...action.payload}
        })
        .addCase(getPost.rejected,(state)=>{ state.loading = false })

        .addCase(getPostAttachments.pending,(state)=>{ state.loading = true })
        .addCase(getPostAttachments.fulfilled,(state,action)=>{ 

            state.loading = false;
            state.current.attachments = action.payload.attachments
        })
        .addCase(getPostAttachments.rejected,(state)=>{ state.loading = false })

        .addCase(getPostHistory.fulfilled,(state,action)=>{ 
            //state.loading = false;
            state.current.history = action.payload 
        })

        .addCase(getPostAuthor.pending,(state)=>{ state.loading = true })
        .addCase(getPostAuthor.fulfilled,(state,action)=>{
            state.loading = false;
            state.current.userAuthor = action.payload 
        })
        .addCase(getPostAuthor.rejected,(state)=>{ state.loading = false })

        .addCase(getPostComments.pending,(state)=>{ state.loadingComments = true })
        .addCase(getPostComments.fulfilled,(state,action)=>{

            state.loadingComments = false;
            if(action.meta.arg.page === 0) {
                state.current.comments = []
            }
            state.current.comments = [...state.current.comments, ...action.payload]
            state.current.hasMoreComments = action.payload.length == DEFAULT_LIMIT

        })
        .addCase(getPostComments.rejected,(state)=>{ state.loadingComments = false })



        .addCase(myPostRights.fulfilled,(state,action)=>{
            state.current.myRights = action.payload

        })

        .addCase(addPostComment.pending,(state)=>{ state.loadingComments = true })
        .addCase(addPostComment.fulfilled,(state,action)=>{
            if(action.payload) {
                if(action.payload.comment_id > 0 ) {
                    for(let i=0; i < state.current.comments.length; i++) {
                        if(state.current.comments[i]["id"] === action.payload.comment_id) {
                            //state.current.comments[i]["children"] = [action.payload, ...state.current.comments[i]["children"]]
                            state.current.comments[i]["children"] = [ ...state.current.comments[i]["children"], action.payload]
    
                            break;
                        }
                    }
                }
                else {
                    state.current.comments = [action.payload, ...state.current.comments]
                }
            } 
            state.loadingComments = false

        })
        .addCase(addPostComment.rejected,(state)=>{ state.loadingComments = false })


        .addCase(editPostComment.pending,(state)=>{ state.loadingComments = true })
        .addCase(editPostComment.fulfilled,(state,action)=>{
            let found = false;
            for(let i=0; i < state.current.comments.length && !found; i++) {
                if(state.current.comments[i]["id"] === action.payload.id) {
                    state.current.comments[i]["content"] = action.payload.content
                    state.current.comments[i]["updated_by"] = action.payload.updated_by
                    state.current.comments[i]["last_update"] = action.payload.last_update
                    found = true
                }
                else {
                    for(let j=0; j < state.current.comments[i]["children"].length && !found; j++) {
                        if(state.current.comments[i]["children"][j]["id"] === action.payload.id) {
                            state.current.comments[i]["children"][j]["content"] = action.payload.content
                            state.current.comments[i]["children"][j]["updated_by"] = action.payload.updated_by
                            state.current.comments[i]["children"][j]["last_update"] = action.payload.last_update
                            found = true
                        }
                    }
                }
            }


            state.loadingComments = false

        })
        .addCase(editPostComment.rejected,(state)=>{ state.loadingComments = false })

        .addCase(deletePostComment.pending,(state)=>{ state.loadingComments = true })
        .addCase(deletePostComment.fulfilled,(state,action)=>{
        
            let found = false;
            for(let i=0; i < state.current.comments.length && !found; i++) {
                if(state.current.comments[i]["id"] === action.payload.id) {
                    state.current.comments.splice(i,1)
                    found = true
                }
                else if(state.current.comments[i]["children"].length > 0) {
                    //check in subcomments
                    for(let j=0; j < state.current.comments[i]["children"].length; j++) {
                        //console.log(state.current.comments[i]["children"][j]["id"],action.payload.id)
                        if(state.current.comments[i]["children"][j]["id"] === action.payload.id) {

                            state.current.comments[i]["children"].splice(j,1)
                            found = true
                            break;
                        }
                    }
                }
            }

            state.loadingComments = false;


        })
        .addCase(deletePostComment.rejected,(state)=>{ state.loadingComments = false })

        .addCase(deletePost.pending,(state)=>{ state.loading = true })
        .addCase(deletePost.fulfilled,(state,action)=>{ state.loading = false; })
        .addCase(deletePost.rejected,(state)=>{ state.loading = false })
        .addCase(deletePosts.pending,(state)=>{ state.loading = true })
        .addCase(deletePosts.fulfilled,(state,action)=>{ state.loading = false; })
        .addCase(deletePosts.rejected,(state)=>{ state.loading = false })
       
        .addCase(editPosts.pending,(state)=>{ state.loading = true })
        .addCase(editPosts.fulfilled,(state,action)=>{ state.loading = false; })
        .addCase(editPosts.rejected,(state)=>{ state.loading = false })

        .addCase(movePost.pending,(state)=>{ state.loading = true })
        .addCase(movePost.fulfilled,(state,action)=>{
        
            state.loading = false;
           
            state.current["folder_id"] = action.payload.folder_id

        })
        .addCase(movePost.rejected,(state)=>{ state.loading = false })

        .addCase(editPost.pending,(state)=>{ state.loading = true })
        .addCase(editPost.fulfilled,(state,action)=>{
            state.current.title = action.payload.title
            state.current.content = action.payload.content
            state.loading = false;
        })
        .addCase(editPost.rejected,(state)=>{ state.loading = false })


        .addCase(addAttachment.pending,(state)=>{ state.loading = true })
        .addCase(addAttachment.fulfilled,(state,action)=>{ state.loading = false; })
        .addCase(addAttachment.rejected,(state)=>{ state.loading = false })

        .addCase(removeAttachment.pending,(state)=>{ state.loading = true })
        .addCase(removeAttachment.fulfilled,(state,action)=>{ state.loading = false; })
        .addCase(removeAttachment.rejected,(state)=>{ state.loading = false })
    }
})



export default postsSlice.reducer;
