
export const COMMON_FOLDER = 3
export const ALERT_FOLDER = 4
export const NOTICE_FOLDER = 5

export const MAX_ATTACHMENT_SIZE = 200 * 1024 * 1024

export const FACEBOOK_URL = "https://www.facebook.com/CISL.Confederazione.Italiana.Sindacati.Lavoratori/"
export const INSTAGRAM_URL = "https://www.instagram.com/cisl_nazionale/D54"
export const YOUTUBE_URL = "https://www.youtube.com/channel/UCWkiWJAcXXF33vOHyiruoGA"
export const TWITTER_URL = "https://twitter.com/CISLNazionale"

export const VERSION = "1.0.1.1"


export const RIGHTS = [
    'read',
    'write',
    'write_adv',
    'moderation',
    'permission_management',
    'view_history',
    'write_comment'
]



export const DEFAULT_LIMIT = 10